import { renderToStaticMarkup } from 'react-dom/server'

import moment from 'moment'
import { formatListingForExport } from 'helpers/listing_builder'
import { isAsin } from 'helpers/amazon'
import { formatTrackerForExport } from 'helpers/tracker/csv'
import { getCurrencyCode, parseCurrency } from 'helpers/currency'
import { getLanguageCode } from 'helpers/mws'
import { formatVolume, humanizeLongNumberWithLocale } from 'helpers/formatters'
import { isArrayEmpty } from 'helpers/arrays'
import { translateEaseOfRank } from 'helpers/keyword'
import { sendSegmentFileDownloadedEvent } from 'helpers/segment'

export const downloadFile = (fileName, urlData) => {
  const aLink = document.createElement('a')
  const evt = new MouseEvent('click')
  aLink.download = fileName
  aLink.href = urlData
  aLink.dispatchEvent(evt)
}

const getKeywordColumnContent = (keyword, column, currencyCode) => {
  const columnAccessor = column.accessor || column.name
  const val = keyword[columnAccessor]
  let content = ''
  switch (columnAccessor) {
    case 'name':
      content = `${(val || '---').replace(/,/g, ' ')} ,`
      break
    case 'exactSearchVolume':
    case 'broadSearchVolume':
    case 'estimatedExactSearchVolume':
    case 'estimatedBroadSearchVolume':
      content = `"${formatVolume(val, false)}",`
      break
    case 'broadSuggestedBidMedian':
    case 'exactSuggestedBidMedian':
      content = `"${val ? parseCurrency(val, currencyCode) : '---'}",`
      break
    case 'matches':
      content = `"${keyword.matches ? parseInt(keyword.matches, 10) : '---'}",`
      break
    case 'quarterlyTrend':
    case 'monthlyTrend':
      content = `"${val === null ? '---' : `${val}%`}",`
      break
    case 'easeOfRankingScore':
      content = `${renderToStaticMarkup(translateEaseOfRank(val)).replace(
        /(<([^>]+)>)/gi,
        ''
      )},`
      break
    default:
      if (isAsin(column.name)) {
        keyword.competitorOrganicRank.forEach(asin => {
          if (asin.asin === column.name) {
            content = `"${asin.organic_rank || '---'}",`
          }
        })
      } else {
        content = `"${val || '---'}",`
      }
      break
  }

  return content
}

const calcMaxDigitsNumber = (value, column) => {
  if (column === 'gross_margin') return value >= 10 ? 0 : 1
  return 2
}

// formatting to match Products Table
const getProfitOverviewColumnContent = (product, column, country_code) => {
  const columnAccessor = column.accessor || column.name
  const val = product[columnAccessor]
  const languageCode = getLanguageCode(country_code)
  const currencyCode = getCurrencyCode(country_code)
  let content = ''

  switch (columnAccessor) {
    case 'title':
      content = `"${(val || '---').replace(/,/g, ' ')}","${product.asin}","${
        product.sku
      }",`
      break
    case 'total_sales':
    case 'cogs':
    case 'opex':
    case 'net_profit':
    case 'organic_sales':
    case 'ppc_sales':
      content = `"${val ? parseCurrency(val, currencyCode) : 0}",`
      break
    case 'gross_margin':
    case 'refund_rate':
    case 'ppc_revenue_percentage':
    case 'roi': {
      const displayValue = parseFloat(val) * 100
      content = `"${humanizeLongNumberWithLocale(
        displayValue,
        languageCode,
        0,
        calcMaxDigitsNumber(Math.abs(displayValue), column)
      )}%",`
      break
    }
    default:
      content = `"${val || 0}",`
      break
  }

  return content
}

export const csvProfitOverviewExport = (
  { products, selectedProductsTableColumns, search },
  country_code
) => {
  if (
    !selectedProductsTableColumns ||
    isArrayEmpty(selectedProductsTableColumns)
  ) {
    return
  }

  const dateObject = new Date(Date.now())
  const dateStr = moment(dateObject).format('YYYY-MM-DD h:m:s')
  const columnsToExport = [...selectedProductsTableColumns]

  let csvContent = 'JUNGLESCOUT PROFIT OVERVIEW CSV EXPORT \n'
  csvContent += `Report Generated at: ${dateObject}\n`
  csvContent += `Search Term(s): ${search ? `"${search}"` : ''} \n`
  csvContent += '\n'
  csvContent += `${columnsToExport
    .map(col => {
      const name = renderToStaticMarkup(col.name).replace(/(<([^>]+)>)/gi, '')
      if (col.accessor === 'title') {
        return `${name}, ASIN, SKU`
      }

      return name
    })
    .join(', ')}\n`

  products.forEach(product => {
    columnsToExport.forEach(column => {
      csvContent += getProfitOverviewColumnContent(
        product,
        column,
        country_code
      )
    })
    csvContent = csvContent.replace(/,$/, '\n')
  })

  const fileName = `Jungle Scout Profit Overview CSV Export - ${dateStr}.csv`
  downloadFile(
    fileName,
    `data:text/csv;charset=UTF-8,${encodeURIComponent(csvContent)}`
  )
  sendSegmentFileDownloadedEvent(
    'Profit Overview',
    'Profit Overview CSV Export.csv'
  )
}

export const csvKeywordListExport = (keywordListName, keywords, columns) => {
  const dateObject = new Date(Date.now())
  const dateStr = moment(dateObject).format('YYYY-MM-DD h:m:s')
  let csvContent = 'JUNGLESCOUT KEYWORD LIST CSV EXPORT \n'
  csvContent += `Report Generated at: ${dateObject}\n`
  csvContent += `Report Generated at: ${keywordListName}\n`
  csvContent += '\n'
  csvContent += `${columns
    .map(col => {
      const colName = col.name || col.Header
      if (typeof colName === 'object') {
        return renderToStaticMarkup(colName).replace(/(<([^>]+)>)/gi, '')
      }
      return colName
    })
    .join(', ')}\n`
  keywords.forEach(keyword => {
    columns.forEach(column => {
      csvContent += getKeywordColumnContent(
        keyword,
        column,
        getCurrencyCode(keyword.country)
      )
    })
    csvContent = csvContent.replace(/,$/, '\n')
  })

  const fileName = `Jungle Scout Keyword List CSV Export - ${dateStr}.csv`
  downloadFile(
    fileName,
    `data:text/csv;charset=UTF-8,${encodeURIComponent(csvContent)}`
  )
  sendSegmentFileDownloadedEvent('Keyword Lists', 'Keyword List CSV Export.csv')
}

export const csvListingsExport = (listings, isV2 = false) => {
  if (!listings || isArrayEmpty(listings)) {
    return
  }

  const dateObject = new Date(Date.now())
  let csvContent = isV2
    ? 'Parent ASIN,ASIN,SKU,Listing Name,Status,Listing Optimization Score,Product Title,Feature #1,Feature #2,Feature #3,Feature #4,Feature #5,Description (HTML included),Backend Search Terms,Last Updated\n'
    : 'ASIN,SKU,Listing name,Status,Listing Optimization Score,Product Title,Feature #1,Feature #2,Feature #3,Feature #4,Feature #5,Description (HTML included),Backend Search Terms,Date Created,Last Updated\n'

  csvContent += listings
    .map(listing => formatListingForExport(listing, isV2))
    .join('\n')

  const fileName = `Jungle Scout - Listing Builder export - ${dateObject}.csv`
  downloadFile(
    fileName,
    `data:text/csv;charset=UTF-8,${encodeURIComponent(csvContent)}`
  )
  sendSegmentFileDownloadedEvent(
    'Listing Builder',
    'Listing Builder export.csv'
  )
}

export const csvTrackerExport = (columns, products, selectedMonths, t) => {
  const { fileName, csvContent } = formatTrackerForExport(
    columns,
    products,
    selectedMonths,
    t
  )

  downloadFile(
    fileName,
    `data:text/csv;charset=UTF-8,${encodeURIComponent(csvContent)}`
  )
  sendSegmentFileDownloadedEvent(
    'Product Tracker',
    'Segment Brand Products Export.csv'
  )
}
