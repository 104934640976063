import round from 'lodash/round'
import moment from 'moment'
import { DATE_RANGES_DAYS } from 'constants/product_tracker/tracker'

import { numberOfDaysXMonthsFromNow } from '../helpers/date'
import { median, mean } from '../helpers/math'
import { isValidValue } from '../helpers/values'

const calculateAverages = scrapes => {
  const prices = scrapes.map(scrape => scrape.price).filter(Boolean)
  const ranks = scrapes.map(scrape => scrape.rank).filter(Boolean)
  const unitsSold = scrapes.map(scrape => scrape.sales).filter(Boolean)

  const rawMedianPrice = median(prices)
  const avgPrice = round(rawMedianPrice, 2)

  const rawAverageRank = mean(ranks)
  const avgRank = round(rawAverageRank)

  const rawAverageSales = mean(unitsSold)
  const avgSales = round(rawAverageSales, 2)

  const avgRevenue =
    isValidValue(avgPrice) && isValidValue(avgSales)
      ? round(avgPrice * avgSales, 2)
      : null
  const avgMonthlySales = round(avgSales * 30)
  const avgMonthlyRevenue =
    isValidValue(avgPrice) && isValidValue(avgMonthlySales)
      ? round(avgPrice * avgMonthlySales, 2)
      : null

  return {
    avg_price: avgPrice,
    avg_rank: avgRank,
    avg_sales: avgSales,
    averageMonthlySales: avgMonthlySales,
    avg_revenue: avgRevenue,
    averageMonthlyRevenue: avgMonthlyRevenue
  }
}

export const run = ({ id, message, scrapes }) => {
  if (!scrapes || scrapes.length === 0) return {}

  const result = {}

  DATE_RANGES_DAYS.forEach(month => {
    const range = numberOfDaysXMonthsFromNow(month)
    const rangeStartDate = moment().subtract(range, 'days')
    const rangeScrapes = scrapes
      .filter(scrape =>
        moment(scrape.date)
          .utc()
          .isAfter(rangeStartDate)
      )
      .reverse()

    const averages = calculateAverages(rangeScrapes)

    result[month] = {
      message,
      product_id: id,
      selected_days: range,
      averages,
      data: rangeScrapes.map(scrape => ({
        date: scrape.date,
        dailySales: scrape.sales,
        rank: scrape.rank,
        price: scrape.price
      }))
    }
  })

  return result
}
